<template>

  <v-container class="wrapper" fluid >
    <v-row no-gutters>
      <v-col cols="12">
        <v-icon class="closeIcon" @click="$emit('close')">mdi-close</v-icon>
        <span class="title">{{ title }}</span>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <template v-if="!chartOptions">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </template>
        <highcharts v-if="chartOptions" :options="chartOptions"></highcharts>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {Chart} from 'highcharts-vue'
import ResultsService from "@/services/ResultsService";


export default {
  name: "VariableBlockChartComponent",
  props: { variables: Array, index: Number, componentData: Object},
  components: {
    highcharts: Chart
  },
  data () {
    return {
      chartOptions: null
    }
  },
  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'isUserRoot']),
    title () {
      return this.variables[this.index].title
    }
  },
  watch: {
    $route: {
      handler() {
        this.loadChartData()
      },
      immediate: true
    },
    index: function() {
      // console.log('Change from ', oldIndex, " to ", newIndex)
      this.chartOptions = null
      this.loadChartData()
    }
  },
  methods: {
    loadChartData() {
      // console.log("TODO load chart data")
      ResultsService.fetchVariableChart(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath, this.index).then((response) => {
        this.chartOptions = response.data
      })
    }
  }
}
</script>

<style scoped>
.closeIcon {
  padding-left: 10px;
  padding-right: 32px;
  cursor: pointer;
}
.title {
  /*font-size: 16px !important;*/
  font-size: calc((16/16)*1rem) !important;
  font-family: "Roboto Black", sans-serif;
  font-weight: 600;
}
.wrapper {
  border-radius: 5px;
  background-color: white;
}

</style>