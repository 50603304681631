<template>
  <div>
    <div v-if="selectionBlockBus==null">
      <span v-for="(period, index) in this.componentData.periods" :key="period">
        <span v-if="index>0">|</span>
        <a @click="onPeriodClick(period)">{{getNameForPeriodType(period)}}</a>
      </span>

      <span v-for="(period, index) in this.componentData.customPeriods" :key="period.extname">
        <span v-if="index>0">|</span>
        <a @click="onPeriodClick(period.extname)">{{period.title[getLocale]}}</a>
      </span>

    </div>
    <template v-if="dataLoaded">
      <highcharts :options="chartOptions"/>
    </template>
    <template v-else>
      <v-skeleton-loader type="image"></v-skeleton-loader>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Chart} from 'highcharts-vue'
import ResultsService from "../../services/ResultsService";
import ColorManager from "../../colormanager";
// import standardNumberDecimals from "../../charts";
import ChartUtil from "../../chartutil";
import Highcharts from "highcharts";

export default {
  name: 'DevChartComponent',

  components: {
    highcharts: Chart
  },

  data () {
    return {
      selectedPeriod: null,
      dataLoaded: false,
      lang: {
        decimalPoint: '.',
        thousandsSep: ','
      },

      chartOptions: {
        lang: ChartUtil.getLangTranslations(this),
        exporting: ChartUtil.getExportingSettings(),
        chart: {
          type: 'line',
          alignTicks: false,
        },

        tooltip: {
          formatter: function () {
            if (this.point.isNull) {
              return 'Null';
            }

            if(this.series.userOptions.type==='column' && !this.series.userOptions.interviews) {
              let timespan = this.point.category

              const divider = timespan.indexOf('/')
              if(divider!==-1) {
                if(this.series.index===0) {
                  timespan = timespan.substring(0, divider)
                } else {
                  timespan = timespan.substring(divider+1)
                }
              }

              return timespan + ": " + Highcharts.numberFormat(this.point.y, 2, ',', '.');
            } else if(this.series.userOptions.type==='column' && this.series.userOptions.interviews) {
              return this.series.name + ": " + Highcharts.numberFormat(this.point.y, 0, ',', '.');
            } else {
              return this.series.name + ": " + Highcharts.numberFormat(this.point.y, 2, ',', '.');
            }
          }
        },

        plotOptions: {

        },

        xAxis: {
        },

        yAxis: [{
          lineWidth: 1,
          title: {
            text: this.$t('CHART_DEVELOPMENT_YAXIS1')
          }
        }
        ],

        series: [],

        title: {
          text: null
        },

        legend: {
          layout: this.componentData.verticalLegend ? 'vertical' : 'horizontal',
        },

        credits: {
          enabled: false
        },

      }
    }

  },

  props: {
    componentData: Object,
    selectionBlockBus: Object
  },

  watch: {
    componentData: function () {
      if(this.selectedPeriod===null) {
        this.selectedPeriod = this.componentData.periods[0]
      }

      this.loadChartData(this.selectedPeriod)
//        console.log("componentData changed!")
    },

    $route: {
      handler() {
        if(this.selectedPeriod===null) {
          this.selectedPeriod = this.componentData.periods[0]
        }

        this.loadChartData(this.selectedPeriod)
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale']),

    numberDecimals() {
      const numberFormat = this.$t('FORMAT_STD_NUMBER')
      const dotIdx = numberFormat.indexOf('.');
      return dotIdx!==-1 ? numberFormat.length-dotIdx-1 : 0;
    },

    comparationChart() {
      return true
    }

  },

  methods: {

    menuCallback(period) {
      this.loadChartData(period)
      this.selectedPeriod = period
    },

    loadChartData(period) {
      this.dataLoaded = false
      if( this.selectionBlockBus != null) {
        this.selectionBlockBus.addMenu("periods", this.getPeriodsMenu(), this.menuCallback)
      }

      const configId = this.componentData.id
      ResultsService.fetchDevChartData(this.getAuthToken, this.getActiveSurvey.extName, configId, period, this.getFilteredDrillPath).then((response) => {
        this.updateChart(response.data)
      })
    },

    onPeriodClick(period) {
      this.loadChartData(period)
    },

    getPeriodsMenu() {

      let menus = []

      for(const p of this.componentData.periods) {
        menus.push( {title: this.getNameForPeriodType(p), name: p}  );
      }

      for(const cp of this.componentData.customPeriods) {
        menus.push( {title: cp.title[this.getLocale], name: cp.extname}  );
      }

      return menus
    },

    getNameForPeriodType(period) {
      if(period==='days') {
        return this.$t('DAYS')
      }if(period==='weeks') {
        return this.$t('WEEKS')
      } else if(period==='months') {
        return this.$t('MONTHS')
      } else if(period==='quarters') {
        return this.$t('QUARTERS')
      } else if(period==='years') {
        return this.$t('YEARS')
      }

      return "?"
    },

    updateChart(dto) {
      this.dataLoaded = true
      let colorManager = new ColorManager()

      const compareMode = (this.componentData.chartType==='column')

      if(this.componentData.chartType) {
        this.chartOptions.chart.type = this.componentData.chartType

        if(this.componentData.chartType==='area') {
          this.chartOptions.plotOptions.area = {
            stacking: 'normal',
            lineColor: '#666666',
            lineWidth: 1,
          }
        } else if(compareMode) {
          this.chartOptions.plotOptions.column = {
            grouping: false,
            shadow: false
          }
        }

      } else if(this.componentData.stacked) {
        this.chartOptions.chart.type = 'area'

        this.chartOptions.plotOptions.area = {
          stacking: 'normal',
          lineColor: '#666666',
          lineWidth: 1,
        }

      }




      this.chartOptions.xAxis.categories = dto.series.map(s => s.name)

      if(this.componentData.lowerY) {
        this.chartOptions.yAxis.min = this.componentData.lowerY
      }
      if(this.componentData.upperY) {
        this.chartOptions.yAxis.max = this.componentData.upperY
      }

      if(this.componentData.showInterviews===true) {
        this.chartOptions.yAxis[1] = {
          gridLineWidth: 0,
          opposite: true,
          title: {
            text: this.$t('CHART_DEVELOPMENT_YAXIS2')
          }
        }
      }

      let allSeries = []

      for(let i=0; i<dto.descriptors.length; ++i) {
        let desc = dto.descriptors[i]

        let values = []

        for (let serie of dto.series) {
          values.push( serie.data[i] )
        }

        if( this.componentData.showInterviews && desc.variable==='interviews' ) {
          let series = {
            name: desc.title,
            type: 'column',
            data: values,
            color:  "#eeeeee",
            yAxis: 1,
            zIndex: -1,

            dataLabels: {
              enabled: true,
              format: "{point.y:.0f}"
            },

            interviews: true
          }

          allSeries[i] = series

        } else {

          let series = {
            data: values,
            name: desc.title,
            color: desc.color ? desc.color : colorManager.getColor(desc.variable),
            zIndex: compareMode ? (i===0 ? 0 : -0.5) : 0,
//            opacity: 0.8,
            pointPlacement: compareMode ? ((i===1) ? 0.0 : 0.2) : 0.0,

            yAxis: 0,
            type: (this.componentData.chartType) ? this.componentData.chartType : (this.componentData.stacked ? 'area' : 'line'),

            dataLabels: {
              enabled: true,
              format: "{point.y:." + this.numberDecimals + "f}"
            },

          }

          allSeries[i] = series
        }

      }

      this.chartOptions.series = allSeries
    }

  },

}
</script>
<style scoped>
</style>
