<template>
  <v-container fluid pa-0>

    <v-row class="pa-3">
      <v-col>
        <v-btn @click="onAddClicked">Add template</v-btn>

        <v-data-table
            :search = "search"
            :headers="headers"
            :items="rows"
            :loading="loading"
            item-key="id"
            class="elevation-2"
            must-sort
            :sort-by.sync="sortBy"
            @click:row="onRowClicked">

          <template v-slot:top>
            <v-text-field v-model="search" label="Filter" class="mx-4"></v-text-field>
          </template>

        </v-data-table>

      </v-col>
    </v-row>


  </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import MailsService from "@/services/MailsService";
import pagenavigator from "@/pagenavigator";

export default {
  name: 'MailTemplatesListComponent',

  components: {
  },

  props: {
    componentData: {
      type: Object,
      required: true
    }
  },


  data () {
    return {
      search: '',
      headers: [
        { text: this.$t('NAME'), align: 'left', value: 'name' },
        { text: "Subject", align: 'left', value: 'subject' },
        { text: "Labels", align: 'left', value: 'labels' },
        { text: "Unlayer", align: 'right', value: 'unlayer' },
      ],

      loading: false,

      rows: [],

      sortBy: 'name'
    }
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath']),
  },

  created: function () {
    this.initialFetchData();
  },

  methods: {

    initialFetchData() {
      this.loading = true
      MailsService.fetchCompoundMailTemplatesListData(this.getAuthToken, this.activeSurvey.extName,  this.componentData.config.id).then((response) => {
        this.rows = response.data
        this.loading = false
      })

      MailsService.fetchUnlayerTemplates(this.getAuthToken, this.activeSurvey.extName,  this.componentData.config.id).then((/*response*/) => {
        // console.log("unlayer!")
        // console.log(response.data)
      })

      MailsService.fetchUnlayerTemplate(this.getAuthToken, this.activeSurvey.extName,  "131919").then((/*response*/) => {
        // console.log("unlayer template")
        // console.log(response.data)
      })


    },

    onRowClicked(row) {
      let pageId = this.$route.params.pageId
      pagenavigator.navigateToMailTemplateDetailsPage(this, this.activeSurvey.extName, row.slug, pageId)
    },

    onAddClicked() {
      let pageId = this.$route.params.pageId
      pagenavigator.navigateToMailTemplateDetailsPage(this, this.activeSurvey.extName, "create", pageId)
    },

  },


}
</script>
