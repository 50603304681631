import backend from '@/services/backend'
// import store from '@/store'

export default {

  fetchFilterData (authToken, surveyName, filtersType) {
    // console.log('fetchFilterData: ' + authToken + ' sn:' + surveyName + " ft:" + filtersType)
    return backend.get('vuejs/filters/list/' + surveyName + "/" + filtersType, { headers: { Authorization: authToken } })
  }

}
