<template>
    <v-btn v-if="showButton" @click="onClick()" :block="componentData.block" :color="componentData.color" :disabled="componentData.disabled">{{componentData.title}}</v-btn>
</template>

<script>

export default {
  name: 'StateButtonComponent',

  data: () => ({}),

  props: {
    componentData: Object,
    sampleId: Number,
    state: Number
  },

  computed: {
    showButton() {
      return this.state===this.componentData.fromState
    }
  },

  methods: {
    requestSave() {},

    onClick() {
      this.$root.$emit('changestate', this.componentData)
    }
  }

}
</script>
