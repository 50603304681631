<template>
  <v-card class="pa-8">
    <v-container class="wrapper" fluid>
      <v-row>
        <v-col>
          <h2>{{'MAIL_CAMPAIGN_INFO' | i18n}}</h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field flat readonly disabled :label="$t('MAIL_CAMPAIGN_NAME_OF')" v-model="campaignInformation.campaignName" ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field flat disabled :label="$t('MAIL_SUBJECT')" v-model="mailSubject" ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field flat disabled :label="$t('MAIL_TEMPLATE')" v-model="campaignInformation.templateName" ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="campaignInformation.filters.length>0">
        <v-col>
          <div><b>{{'MAIL_CAMPAIGN_SELECTED_FILTERS' | i18n}}</b></div>
          <div v-for="(filter,idx) in campaignInformation.filters" :key="idx">{{filter.title}}</div>
        </v-col>
      </v-row>


    </v-container>
  </v-card>
</template>


<script>
import {mapGetters, mapState} from 'vuex'
import MailsService from "@/services/MailsService";

export default {
  name: 'MailCampaignInfo',

  components: {},

  created: function () {
    this.fetchTemplate()
  },

  props: {
    campaignInformation: Object
  },

  data: () => ({
    mailTemplate: null,
//    mailSubject: "abc"
  }),

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

    mailSubject() {
      return this.mailTemplate!==null ? this.mailTemplate.subject : ""
    }
  },

  methods: {

    fetchTemplate() {
      MailsService.fetchMailTemplateData(this.getAuthToken, this.activeSurvey.extName, this.campaignInformation.templateName).then((response) => {
        this.mailTemplate = response.data
      })
    }


  }

}
</script>

<style scoped>

</style>
