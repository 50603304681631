<template>
  <v-container class="wrapper" fluid v-if="!componentData.config.rootOnly || (componentData.config.rootOnly && isUserRoot)">
    <v-row no-gutters >
      <v-col v-if="showCompactActions" cols="8">
        <CompactActionsComponent :open-actions="openActions" :closed-actions="closedActions" :suggested-actions="suggestedActions" :writeable="anchorable" @actionchanged="loadChartData"/>
      </v-col>
      <v-col v-else-if="showLegacyActionPlan" cols="8">
        <ActionPlanPartComponent :action-plan-data="actionPlanData"/>
      </v-col>
      <v-col v-else-if="showFallbackComponent" cols="8">
        <page-component :component-data="componentData.config.fallbackComponent"/>
      </v-col>
      <v-col v-else cols="8">
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-col>

      <v-col  v-if="actionPlanData!==null" cols="4">
        <v-container class="variablesWrapper" fluid >
          <v-row class="variablesRow" no-gutters>

            <template v-for="(vardata, index) in actionPlanData.variables">
              <v-col cols="12" :key="index" class="pt-0" >
                <VariableActionComponent :vardto="vardata" />
              </v-col>
            </template>

          </v-row>

        </v-container>

      </v-col>
      <v-col v-else>
        <v-container class="variablesWrapper" fluid>
            <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import ResultsService from "../../../services/ResultsService";
import ActionPlanPartComponent from "./ActionPlanPartComponent";
import VariableActionComponent from "./VariableActionComponent";
import CompactActionsComponent from "./CompactActionsComponent";

export default {
  name: 'ActionPlanComponent',

  components: {
    CompactActionsComponent, ActionPlanPartComponent, VariableActionComponent, /*, VariableDualComponent, VariableRankComponent*/
    'page-component': () => import('../PageComponent.vue')

  },

  data () {
    return {
      anchorable: null,
      actionPlanData: null,
      mainVarData: null,
      openActions: [],
      closedActions: [],
      suggestedActions: [],
      isActionsConfigured: null,
      actionManagerDataLoaded: true
    }
  },

  props: {
    componentData: Object,
  },

  watch: {
    $route: {
      handler() {
        this.loadChartData()
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale', 'isUserRoot']),

    showCompactActions() {
      return this.isActionsConfigured && this.isActionOverviewConfig
    },

    showLegacyActionPlan() {
      return this.actionPlanData!==null && !this.isActionOverviewConfig
    },

    showFallbackComponent() {
      return !this.showCompactActions && this.componentData.config.fallbackComponent
    },

    isActionOverviewConfig() {
      return this.componentData.type==='ActionOverviewConfig'
    },

    isDataLoaded() {
      return this.isActionOverviewConfig ? this.actionManagerDataLoaded : this.actionPlanData!=null
    }


  },

  methods: {
    loadChartData() {
      if(this.componentData.config) {
        ResultsService.fetchActionPlanData(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath).then((response) => {
          this.actionPlanData = response.data;
        })

        if(this.isActionOverviewConfig) {
          // also fetch data for ActionsPartComponent
          ResultsService.fetchActionManagerData(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath).then((response) => {
            this.anchorable = response.data.anchorable
            this.mainVarData = response.data.mainVariable
            this.openActions = response.data.openActions
            this.isActionsConfigured = response.data.isActionsConfigured
            this.closedActions = response.data.closedActions
            this.suggestedActions = response.data.suggestedActions
            this.actionManagerDataLoaded = true
          })
        }

      }
    },
  }


}
</script>

<style scoped>
.wrapper {
  /*background-color: white;*/
  border-radius: 5px;
}

.variablesWrapper {
  padding-top: 0;

}

.variablesRow {
  padding-top: 0;
}

/*span.title {*/
/*  font-size: 24px;*/
/*}*/

div.waitContainer {
  display: flex;
  justify-content: center;
}


</style>
