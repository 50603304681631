<template>

  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table v-if="tableData!==null"
                :headers="tableData.headers"
                :items="tableData.rows"
                :hide-default-footer="true"
                :disable-pagination="true"
                class="elevation-1"
        />
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import ResultsService from "../../../services/ResultsService";
import {mapGetters, mapState} from "vuex";

export default {
  name: 'DataTableComponent',

  components: {},

  data () {
    return {
      tableData: null
    }
  },


  props: {
      componentData: Object,
  },

  computed: {
    ...mapState(['activeView']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale'])
  },

  methods: {
    loadTableData() {
      ResultsService.fetchDataTableData(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath).then((response) => {
        this.tableData = response.data
      })
    },
  },

  watch: {
    $route: {
      handler() {
        this.loadTableData()
      },
      immediate: true
    }
  },



}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrapper {
  color: white;
  height: 100%;
  border-radius: 5px;
}

div.title {
  /*font-size: 24px !important;*/
  font-size: calc((24/16)*1rem) !important;
  font-weight: normal;
  line-height: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

div.score {
  font-size: 48px;
  font-size: calc((48/16)*1rem);
  font-weight: 500;
}
</style>
