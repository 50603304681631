import backend from '@/services/backend'

export default {

  fetchCompoundMailTemplatesListData (authToken, surveyName, configId) {
    return backend.get('vuejs/mails/compoundtemplates/' + surveyName + '/' + configId, { headers: { Authorization: authToken } })
  },

  fetchMailTemplatesListData (authToken, surveyName, configId) {
    return backend.get('vuejs/mails/templates/' + surveyName + '/' + configId, { headers: { Authorization: authToken } })
  },

  fetchMailTemplatesListDataNoLabel (authToken, surveyName) {
    return backend.get('vuejs/mails/templates/' + surveyName, { headers: { Authorization: authToken } })
  },


  fetchMailTemplateData(authToken, surveyName, templateName) {
    return backend.get('vuejs/mails/template/' + surveyName + '/' + templateName, { headers: { Authorization: authToken } })
  },

  createMailTemplate(authToken, surveyName, templateDetails, testmail) {
    const mailparam = testmail ? "?testmail="+testmail : ""
    return backend({ method: 'post', url: 'vuejs/mails/template/create/' + surveyName + mailparam, data: templateDetails,  headers: { Authorization: authToken } })
  },

  updateMailTemplate(authToken, surveyName, templateDetails, testmail) {
    const mailparam = testmail ? "?testmail="+testmail : ""
    return backend({ method: 'put', url: 'vuejs/mails/template/update/' + surveyName + mailparam, data: templateDetails,  headers: { Authorization: authToken } })
  },

  deleteMailTemplate(authToken, surveyName, templateName) {
    return backend.get('vuejs/mails/template/delete/' + surveyName + '/' + templateName, { headers: { Authorization: authToken } })
  },

  fetchMailCampaignsListData (authToken, surveyName, configId, drillPath) {
    return backend.get('vuejs/mailcampaign/list/' + surveyName + '/' + configId + '/' + drillPath, { headers: { Authorization: authToken } })
  },

  fetchMailCampaignData(authToken, surveyName, campaignId) {
    return backend.get('vuejs/mailcampaign/' + surveyName + '/' + campaignId, { headers: { Authorization: authToken } })
  },

  updateMailCampaignData(authToken, surveyName, campaignId, campaignData) {
    return backend({ method: 'put', url: 'vuejs/mailcampaign/' + surveyName + '/' + campaignId, data: campaignData,  headers: { Authorization: authToken } })
  },

  fetchMailTrackingRows(authToken, surveyName, campaignId, from, to, sortBy, sortDesc, filter) {
    return backend.get('vuejs/mailcampaign/trackings/' + surveyName + '/' + campaignId +
        "?from="+from + "&to="+to + "&sortBy="+sortBy + "&sortDesc="+sortDesc + "&filter="+filter,  { headers: { Authorization: authToken } })
  },

  deleteDuplicatesFromMailCampaign(authToken, surveyName, campaignId) {
    return backend.delete('vuejs/mailcampaign/deleteduplicates/' + surveyName + '/' + campaignId, { headers: { Authorization: authToken } })
  },

  deleteMailTracking(authToken, surveyName, campaignId, trackingIds) {
    return backend.delete('vuejs/mailcampaign/trackings/' + surveyName + '/' + campaignId + "?ids=" + trackingIds.join(","), { headers: { Authorization: authToken } })
  },

  fetchRenderedMailData(authToken, surveyName, campaignId, trackingId, templateName) {
    return backend.get('vuejs/mailcampaign/rendermail/' + surveyName + '/' + campaignId + '/' + trackingId + '/' + templateName, { headers: { Authorization: authToken } })
  },

  startMailCampaign(authToken, surveyName, campaignId) {
    return backend.get('vuejs/mailcampaign/start/' + surveyName + '/' + campaignId, { headers: { Authorization: authToken } })
  },

  stopMailCampaign(authToken, surveyName, campaignId) {
    return backend.get('vuejs/mailcampaign/stop/' + surveyName + '/' + campaignId, { headers: { Authorization: authToken } })
  },

  deleteMailCampaign(authToken, surveyName, campaignId) {
    return backend.delete('vuejs/mailcampaign/' + surveyName + '/' + campaignId, { headers: { Authorization: authToken } })
  },


  sendMails(authToken, surveyName, campaignId, trackingIds) {
    return backend.get('vuejs/mailcampaign/sendmail/' + surveyName + '/' + campaignId + "?ids=" + trackingIds.join(","), { headers: { Authorization: authToken } })

  },

  fetchUnlayerTemplates(authToken, surveyName, configId) {
    return backend.get('vuejs/unlayer/templates/' + surveyName + '/' + configId, { headers: { Authorization: authToken } })
  },

  fetchUnlayerTemplate(authToken, surveyName, templateId) {
    return backend.get('vuejs/unlayer/template/' + surveyName + '/' + templateId, { headers: { Authorization: authToken } })
  },

  uploadUnlayerFile(authToken, surveyName, file) {
    let formData = new FormData();

    for (var i = 0; i < file.attachments.length; i++) {
      formData.append('files', file.attachments[i]);
    }

    return backend({ method: 'post', url: 'vuejs/unlayer/uploadfile/' + surveyName, data: formData,
      headers: { Authorization: authToken, 'Content-Type': 'multipart/form-data' } })
  },

  fetchUnlayerImages(authToken, surveyName) {
    return backend.get('vuejs/unlayer/images/' + surveyName, { headers: { Authorization: authToken } })
  },

  deleteUnlayerFile(authToken, surveyName, imageId) {
    return backend.delete('vuejs/unlayer/images/' + surveyName + '/' + imageId, { headers: { Authorization: authToken } })
  },

  fetchUnlayerMergeTags(authToken, surveyName) {
    return backend.get('vuejs/unlayer/mergetags/' + surveyName, { headers: { Authorization: authToken } })
  },

  fetchUnlayerEditorOptions(authToken, surveyName) {
    return backend.get('vuejs/unlayer/editoroptions/' + surveyName, { headers: { Authorization: authToken } })
  },


  // new campaign creation wizard
  fetchCreateCampaignData(authToken, surveyName, mailActionName, filteredDrillPath, selectedFilters, deletedRecipients) {
    return backend.get('vuejs/mailcampaign/createcampaigndata/' + surveyName + '/' + mailActionName + '/' + filteredDrillPath +
        "?filters="+selectedFilters.join()
        + "&deletedRecipients="+deletedRecipients.join(),
        { headers: { Authorization: authToken } })
  },

  fetchNewMailTrackingRows(authToken, surveyName, mailActionName, filteredDrillPath, from, to, sortBy, sortDesc, textFilter, selectedFilters, deletedRecipients) {
    return backend.get('vuejs/mailcampaign/newtrackings/' + surveyName + '/' + mailActionName + '/' + filteredDrillPath +
        "?from="+from + "&to="+to + "&sortBy="+sortBy + "&sortDesc="+sortDesc + "&textfilter="+textFilter
        + "&filters="+selectedFilters.join()
        + "&deletedRecipients="+deletedRecipients.join(),
        { headers: { Authorization: authToken } })
  },

    startMailCampaignNow(authToken, surveyName, mailActionName, filteredDrillPath, campaignName, mailTemplate, fromEmail, selectedFilters, deletedRecipients, scheduledStartDateTime) {
      return backend({ method: 'post', url: 'vuejs/mailcampaign/startcampaignnow/' + surveyName + '/' + mailActionName + '/' + filteredDrillPath
              + "?filters="+selectedFilters.join()
              + "&deletedRecipients="+deletedRecipients.join(),
          data: { campaignName: campaignName, mailTemplate : mailTemplate, fromEmail: fromEmail, scheduledStartDateTime: scheduledStartDateTime },
          headers: { Authorization: authToken } });
  },


}
