<template>
    <v-btn @click="onClick()" :block="componentData.block" :color="componentData.color">{{componentData.title}}</v-btn>
</template>

<script>

export default {
  name: 'UpdateButtonComponent',

  components: {},

  data: () => ({
  }),

  props: {
    componentData: Object,
    sampleId: Number
  },

  methods: {
    requestSave() {},

    onClick() {
      this.$root.$emit('updatesample', true)
    }
  }


}
</script>
<style scoped>
</style>