<template>
  <v-container fluid pa-0>
    <ConfirmDialog ref="confirm" />

    <v-row>
      <v-col class="pt-0 pb-0">
        <DrillPathComponent/>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-0 pb-0">
        <DrillPathBackComponent/>
      </v-col>
    </v-row>

    <v-row class="pt-3 px-3 pb-0">

      <v-col cols="9">
        <v-card>

          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">{{'MAIL_CAMPAIGN_PICK_NAME' | i18n}}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 2" step="2">{{'MAIL_CAMPAIGN_PICK_TEMPLATE' | i18n}}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 3" step="3">{{'MAIL_CAMPAIGN_CHECK_DATA' | i18n}}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="4">{{'MAIL_CAMPAIGN_START' | i18n}}</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form v-model="step1Valid">
                  <div class="stepCaption">{{'MAIL_CAMPAIGN_PICK_NAME' | i18n}}</div>
                  <v-text-field :label="$t('MAIL_CAMPAIGN_NAME_OF')" v-model="campaignName" :rules="mandatoryRules" mandatory> </v-text-field>
                </v-form>

                <div class="buttonContainer">
                  <v-btn color="primary" @click="e1 = 2" :disabled="!step1Valid">{{'NEXT' | i18n}}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>

              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form v-model="step2Valid">

                  <div class="stepCaption">{{'MAIL_CAMPAIGN_PICK_TEMPLATE' | i18n}}</div>

                  <v-autocomplete
                      :disabled="mailTemplates.length===0"
                      v-model="selectedTemplate"
                      :no-data-text="$t('MAIL_CAMPAIGN_PICK_TEMPLATE')"
                      :loading="isLoadingTemplates"
                      :label="$t('MAIL_CAMPAIGN_PICK_TEMPLATE')"
                      auto-select-first :items="mailTemplates ? mailTemplates : []"
                      item-text="name"
                      item-value="slug"
                      :rules="mandatoryRules">
                  </v-autocomplete>

                  <v-autocomplete
                      :disabled="campaignInfo.fromEmails.length===0"
                      v-model="fromEmail"
                      :label="$t('MAIL_CAMPAIGN_PICK_SENDER')"
                      auto-select-first
                      :items="campaignInfo.fromEmails"
                      :rules="mandatoryRules">
                  </v-autocomplete>


                </v-form>

                <div class="buttonContainer2">
                  <v-btn color="primary" @click="e1 = 1" >
                    <v-icon left dark>mdi-chevron-left</v-icon>
                    {{ 'PREV' | i18n }}
                  </v-btn>
                  <v-btn color="primary" @click="e1 = 3" :disabled="!step2Valid">{{ 'NEXT' | i18n }}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>


              </v-stepper-content>

              <!----------------------------- SELECT FILTERS -------------------------------->
              <v-stepper-content step="3">

                <v-container fluid pa-0>
                  <v-row>
                    <v-col><div class="stepCaption">{{'MAIL_CAMPAIGN_CHECK_DATA' | i18n}}</div></v-col>
                  </v-row>

                  <v-row v-for="mailFilter in selectableFilters" :key="mailFilter.extname" class="align-center mt-0">
                    <v-col cols="2" class="pt-0 pb-0">{{mailFilter.title}}</v-col>
                    <v-col cols="1" class="pt-0  pb-0">{{mailFilter.nInterviews}}</v-col>
                    <v-col cols="1" class="pt-0  pb-0"><v-switch @change="onFilterChanged(mailFilter)" :label="'DELETE' | i18n"></v-switch>   </v-col>
                    <v-col cols="4" class="pt-0 pb-0">{{mailFilter.help}}</v-col>
                  </v-row>


                </v-container>

                <div class="buttonContainer2">
                  <v-btn color="primary" @click="e1 = 2" :disabled="!step2Valid">
                    <v-icon left dark>mdi-chevron-left</v-icon>{{ 'PREV' | i18n }}
                  </v-btn>
                  <v-btn color="primary" @click="e1 = 4">{{ 'NEXT' | i18n }}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>

              </v-stepper-content>

              <v-stepper-content step="4">
                <div class="stepCaption">{{'MAIL_CAMPAIGN_START' | i18n}}</div>

                <v-container fluid pa-0>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field readonly disabled :label="$t('NAME')" v-model="campaignName"> </v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>

                    <v-col  cols="6">
                      <v-text-field readonly disabled :label="$t('MAIL_TEMPLATE')" v-model="selectedTemplate"></v-text-field>
                    </v-col>

                  </v-row>

                </v-container>

                <div class="buttonContainer2">
                  <v-btn color="primary" @click="e1 = 3" >
                    <v-icon left dark>mdi-chevron-left</v-icon>{{ 'PREV' | i18n }}
                  </v-btn>

                  <div>

                    <v-dialog width="auto " scrollable>
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" v-on="on" :disabled="recipentsListData.rows.length==0">{{'MAIL_CAMPAIGN_PREVIEW' | i18n}}<v-icon right dark>mdi-eye</v-icon></v-btn>
                      </template>
                      <RenderedMailCarousel :campaign-id="0" :template="selectedTemplate" :tracking-ids="trackingIds" />
                    </v-dialog>

                    <v-dialog v-model="scheduleDialog" width="40%">
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" v-on="on" class="ml-2 mr-2">{{'MAIL_CAMPAIGN_SCHEDULE' | i18n}}<v-icon right dark>mdi-calendar</v-icon></v-btn>
                      </template>

                      <v-card>
                        <v-container fluid>
                          <v-row>
                            <v-col cols="6">
                              <v-text-field v-model="scheduledStartDateTime" hint="Input date and time for initiating mail sending" persistent-hint label="Scheduled time" type="datetime-local" />
                            </v-col>
                          </v-row>

                        </v-container>

                        <v-card-actions>
                          <div class="flex-grow-1"></div>
                          <v-btn color="primary" text @click="scheduleDialog = false">{{'CANCEL' | i18n}}</v-btn>
                          <v-btn color="primary" text @click="onScheduleCampaign">{{'MAIL_CAMPAIGN_SCHEDULE' | i18n}}</v-btn>
                        </v-card-actions>

                      </v-card>

                    </v-dialog>

                    <v-btn color="primary" @click="onStartCampaign">{{'MAIL_CAMPAIGN_START' | i18n}}<v-icon right dark>mdi-play</v-icon></v-btn>

                  </div>
                </div>
              </v-stepper-content>

            </v-stepper-items>

          </v-stepper>

        </v-card>

      </v-col>

      <v-col cols="3">

        <div>

          <v-card>

            <div style="padding: 1em">
              <div style="font-size: calc((30/16)*1rem); color: black">
                {{ campaignInfo.numberCustomers }}
              </div>
              <div style="font-weight: bold;margin-bottom: 1em">
                {{'MAIL_CAMPAIGN_CUSTOMERS_SELECTED' | i18n}}
              </div>

            </div>

          </v-card>

          <v-card style="margin-top: 1em">
            <div style="padding: 1em; display: flex;flex-direction: column">
              <div style="font-size: calc((30/16)*1rem); color: black">{{campaignInfo.pathName}}</div>
              <div style="font-weight: bold; margin-bottom: 1em">{{'MAIL_CAMPAIGN_SELECTED_FILTERS' | i18n}}</div>
<!--              <div>{{'MAIL_CAMPAIGN_INVALID_ANON' | i18n}}({{campaignInfo.nInvalids}})<v-icon>mdi-check</v-icon> </div>-->

              <template v-for="(filterName,idx) in campaignInfo.filters">
                <div :key="idx">{{filterName}}<v-icon>mdi-check</v-icon> </div>
              </template>

              <template v-for="mailFilter in selectedFilters">
                <div :key="mailFilter.extname">{{mailFilter.title}}<v-icon>mdi-check</v-icon> </div>
              </template>

            </div>

          </v-card>

        </div>

      </v-col>

    </v-row>

    <v-row class="pt-0 px-3" v-if="e1==2 && selectedTemplate">
      <v-col cols="9">
        <v-card>
          <MailTemplateDetails :embedded="true" :template-name="selectedTemplate" :key="selectedTemplate"/>
<!--          <RenderedMail :campaign-id="0" :template="selectedTemplate" :tracking-id="0" :key="selectedTemplate" />-->
        </v-card>
      </v-col>
    </v-row>



    <v-row class="pt-3 px-3 pb-0" v-if="e1==4">
      <v-col>
        <v-card>
          <v-data-table
              :headers="headers"
              :server-items-length="recipentsListData.totalRows"
              :items="recipentsListData.rows"
              @update:options="onOptionsChanged"
              :options.sync="options"
              :loading="loadingRecipentsData"
              item-key="id"
              class="elevation-2"
              :items-per-page="50"
              must-sort
              :sort-by.sync="sortBy">

            <template v-slot:top>
              <v-row>
                <v-col>
                  <v-text-field v-model="search" label="Filter" class="mx-4" @change="onFilterEnter"></v-text-field>
                </v-col>
              </v-row>
            </template>

            <template v-slot:item.viewTemplate="{ item }">
              <v-dialog width="auto ">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :disabled="!selectedTemplate"  class="viewButton" text v-bind="attrs" v-on="on">{{'MAIL_CAMPAIGN_PREVIEW' | i18n}}</v-btn>
                </template>

                <RenderedMail :campaign-id="0" :template="selectedTemplate" :tracking-id="item.id"  />

              </v-dialog>
            </template>

            <template v-slot:item.actions="{ item }">
              <!--              <v-icon small class="mr-2" v-if="campaignDetails.mailTemplate" @click="onMailItem(item)">mdi-email</v-icon>-->
              <v-icon small @click="onDeleteItem(item)">mdi-delete</v-icon>
            </template>


          </v-data-table>

        </v-card>

      </v-col>

    </v-row>


  </v-container>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import DrillPathComponent from "@/components/DrillPathComponent";
import DrillPathBackComponent from "@/components/DrillPathBackComponent";
import MailsService from "@/services/MailsService";
import RenderedMail from "@/views/mailcampaigns/RenderedMail";
import Pagenavigator from "@/pagenavigator";
import ConfirmDialog from "@/components/ConfirmDialog";
import RenderedMailCarousel from "@/views/mailcampaigns/RenderedMailCarousel";
import MailTemplateDetails from "@/views/mailtemplates/MailTemplateDetails";

export default {
  name: 'CreateCampaignPage',

  components: {
    MailTemplateDetails,
    RenderedMailCarousel,
    ConfirmDialog,
    RenderedMail,
    DrillPathBackComponent,
    DrillPathComponent
  },

  created: function () {
    this.fetchCampaignData();

    this.isLoadingTemplates = true

    MailsService.fetchMailTemplatesListDataNoLabel(this.getAuthToken, this.activeSurvey.extName).then((response) => {
      this.mailTemplates = response.data
      this.isLoadingTemplates = false
    })


  },

  data() {
    return {
      e1: 1,

      scheduleDialog: false,
      scheduledStartDateTime: null,

      campaignName: "",
      mailSubject: "",
      fromEmail: "",

      step1Valid: false,
      step2Valid: false,

      campaignInfo: {
        numberCustomers: "",
        pathName: "",
        filters: [],
        fromEmails: [],
        mailFilters: [],
      },

      selectedTemplate: null,
      mailTemplates: [""],
      isLoadingTemplates: false,

      ////////////////////////////////////////////////////////////////////
      // recipients table
      search: '',

      headers: [
        { text: this.$t('NAME'), align: 'left', value: 'respondent' },
        { text: this.$t('EMAIL'), align: 'left', value: 'email' },
        { text: this.$t('STATE_FILTER'), align: 'left', value: 'status' },
        { text: this.$t('MAIL_CAMPAIGN_PREVIEW'), align: 'left', value: 'viewTemplate' },
        { text: this.$t('ACTIONS'), value: 'actions', sortable: false },

      ],

      options: {},

      loadingRecipentsData: false,
      recipentsListData: {
        rows: [],
        totalRows: 0
      },

      sortBy: 'id',

      deletedRows: [],

      activeFilters: []


    }
  },

  computed: {
    ...mapState(['activeSurvey', 'interviewData']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath']),

    mandatoryRules() {
      return  [
        value => !!value || this.$t('VALIDATE_ERROR_MANDATORY'),
      ]
    },

    selectableFilters() {
      return this.campaignInfo.mailFilters.filter(mailFilter => mailFilter.extname!=='illegal')
    },

    selectedFilters() {
      return this.campaignInfo.mailFilters.filter(mailFilter => this.activeFilters.includes(mailFilter.extname) || mailFilter.extname==='illegal')
    },

    unselectedFilters() {
      return this.campaignInfo.mailFilters.filter(mailFilter => !this.activeFilters.includes(mailFilter.extname))
    },

    trackingIds() {
      return this.recipentsListData.rows.map(e => e.id)
    }


  },

  methods: {
    onFilterEnter() {
      this.onOptionsChanged(this.options)
    },

    onFilterChanged(filter) {
      if(!this.activeFilters.includes(filter.extname)) {
        this.activeFilters.push(filter.extname)
      } else {
        this.activeFilters = this.activeFilters.filter(e => e !== filter.extname)
      }

      this.fetchCampaignData();

    },

    isFilterActive(filterName) {
      return this.activeFilters.contains(filterName)
    },


    fetchCampaignData() {
      MailsService.fetchCreateCampaignData(this.getAuthToken, this.activeSurvey.extName,  this.$route.params.mailAction, this.getFilteredDrillPath, this.activeFilters, this.deletedRows).then((response) => {
        this.campaignInfo = response.data
        if(this.campaignInfo.fromEmails.length>0) {
          this.fromEmail = this.campaignInfo.fromEmails[0]
        }
      })
    },

    onOptionsChanged(options) {
      const pageNumber = options.page
      const pageSize = options.itemsPerPage

      const from = (pageNumber-1)*pageSize
      const to = from + pageSize

      const sortBy = options.sortBy[0]
      const sortDesc = options.sortDesc[0]

      this.loadingRecipentsData = true

      MailsService.fetchNewMailTrackingRows(this.getAuthToken, this.activeSurvey.extName, this.$route.params.mailAction, this.getFilteredDrillPath, from, to, sortBy, sortDesc, this.search, this.activeFilters, this.deletedRows).then((response) => {
        this.recipentsListData = response.data
        this.loadingRecipentsData = false
      })

    },

    onDeleteItem(row) {
      this.deletedRows.push(row.id)
      this.onOptionsChanged(this.options)
      this.fetchCampaignData()
    },

    async onStartCampaign() {
      this.scheduleDialog = false
      this.scheduledStartDateTime = null
      this.showStartConfirmation()
    },

    onScheduleCampaign() {
      this.scheduleDialog = false
      this.showStartConfirmation()
    },

    async showStartConfirmation() {
      const details = this.campaignName + "(" + this.campaignInfo.numberCustomers + " " + this.$t('MAILS')  + ")"

      let title = this.$t('MAIL_CAMPAIGN_START') + " " + details

      if(this.scheduledStartDateTime) {
        const d = new Date(this.scheduledStartDateTime)
        const hour = String(d.getHours()).padStart(2, '0');
        const minute = String(d.getMinutes()).padStart(2, '0');

        const datetime = hour + ":" + minute + " " + d.toLocaleDateString()
        title = "Schedule " + details + " for start at " + datetime
      }

      if ( await this.$refs.confirm.open(this.$t('CONFIRM'), title  )) {
        MailsService.startMailCampaignNow(this.getAuthToken, this.activeSurvey.extName, this.$route.params.mailAction, this.getFilteredDrillPath, this.campaignName, this.selectedTemplate, this.fromEmail, this.activeFilters, this.deletedRows, this.scheduledStartDateTime).then((/*response*/) => {
          this.$store.commit('showSnackbar', this.campaignName + " is now running")
          Pagenavigator.navigateToMailCampaignOverviewPage(this, this.activeSurvey.extName, this.getFilteredDrillPath)
        })
      }
    },


  }



}
</script>
<style scoped>
div.stepCaption {
  /*font-size: 30px;*/
  font-size: calc((30/16)*1rem);
  margin-bottom: 1em;
}

div.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

div.buttonContainer2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


</style>