var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","pa-0":""}},[(!_vm.showCampaignDetails)?_c('v-row',{staticClass:"pa-3"},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.rows,"loading":_vm.loading,"items-per-page":50,"item-key":"id","multi-sort":false},on:{"click:row":_vm.onRowClicked},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Filter"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created).toLocaleDateString())+" ")]}},{key:"item.scheduled",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.scheduled ? new Date(item.scheduled).toLocaleDateString() +' '+ new Date(item.scheduled).toLocaleTimeString() : '')+" ")]}},{key:"item.rateOpened",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.rateOpened.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1} ))+"% ")]}},{key:"item.rateClicked",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.rateClicked.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1} ))+"% ")]}}],null,false,2594126910)})],1)],1):[_c('div',{staticClass:"pl-3"},[_c('v-btn',{attrs:{"color":"grey","dark":""},on:{"click":_vm.onReturnToListClicked}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" "+_vm._s(_vm._f("i18n")('BACK'))+" ")],1)],1),_c('v-row',{staticClass:"pa-3"},[_c('v-col',[_c('v-card',[_c('div',{staticClass:"backDiv"},[_c('div',{staticClass:"campaignNameDiv"},[_vm._v(_vm._s(_vm.campaignName))]),_c('v-btn',{staticClass:"mr-4",on:{"click":_vm.onInformationClicked}},[_vm._v(_vm._s(_vm._f("i18n")('MAIL_CAMPAIGN_INFO')))])],1)])],1)],1),_c('v-row',[_c('v-col',[_c('MailCampaignDetails',{attrs:{"campaign-id-param":_vm.getSelectedCampaignId,"show-info-dialog":_vm.showInfoDialog}})],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }