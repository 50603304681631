<template>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col offset=4 cols="4" class="logocol">
        <img width="220px" src="../assets/login_logo.png">
      </v-col>

      <v-col offset=4 cols="4">
        <v-card class="elevation-12 pa-10">
          <v-card-title class="mainTitle">Forgot your password?</v-card-title>
          <v-card-subtitle class="subTitle">Type e-mail address to receive a reset link</v-card-subtitle>

          <v-card-text>
            <v-form @submit.prevent="onRequestLink" v-model="valid">
              <v-text-field
                  v-model.lazy.trim="username"
                  name="login"
                  label="e-mail"
                  type="text"
                  autocomplete="username"
                  :error-messages="warningText"
                  required />
              <v-btn type="submit" :disabled="username.length===0"  color="primary">Request link</v-btn>
            </v-form>


          </v-card-text>

        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters class="footerRow">
      ag analytics &middot; +45 7020 1075 &middot; <a class="frontLink" href="mailto://support@aganalytics.dk">support@aganalytics.dk</a>
    </v-row>

  </v-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import PageNavigator from "../pagenavigator"
import AuthService from "../services/AuthService";

export default {
  name: 'forgot',

  data: function () {
    return {
      cookieLogin: false,

      valid: false,

      showWaitScreen: false,

      username: '',

      warningText: null

    }
  },

  computed: {
    ...mapState(['userIdentity', 'activeSurvey', 'activeDrillPath']),
    ...mapGetters(['getFilteredDrillPath']),
  },


  methods: {
    onRequestLink: function () {

      AuthService.requestResetPasswordLink(this.username).then((response) => {
        const status = response.data.status

        if(status===0) {
          this.$store.commit('setSnackText', "Link request send")
          PageNavigator.navigateToLoginPage(this)
        } else if(status===1) {
          this.warningText = ["unknown user"]
        } else if(status===2) {
          this.warningText = ["user disabled"]
        }

      })

    },

  },

}
</script>

<style scoped>
div.loginButtonPanel {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;
}

div.logocol {
  display: flex;
  justify-content: center;
}

div.mainTitle {
  justify-content: center;
  /*font-size: 30px;*/
  font-size: calc((30/16)*1rem);

}

div.subTitle {
  text-align: center;
  font-size: calc((18/16)*1rem);
  /*font-size: 18px;*/
}

div.footerRow {
  align-self: flex-end;
  justify-content: center;
  color: #888888
}

a.frontLink {
  margin-left: 6px;
  color: #888888;
  text-decoration: none;
}

</style>

