<template>
  <v-container fluid pa-0>

    <v-row class="pa-3" v-if="!showCampaignDetails">
      <v-col>
        <v-data-table
            :search = "search"
            :headers="headers"
            :items="rows"
            :loading="loading"
            :items-per-page="50"
            item-key="id"
            class="elevation-2"
            @click:row="onRowClicked"
            :multi-sort="false"
        >

          <template v-slot:top>
            <v-text-field v-model="search" label="Filter" class="mx-4"></v-text-field>
          </template>

          <template v-slot:item.created="{ item }">
            {{    new Date(item.created).toLocaleDateString() }}
          </template>

          <template v-slot:item.scheduled="{ item }">
            {{item.scheduled ? new Date(item.scheduled).toLocaleDateString() +' '+ new Date(item.scheduled).toLocaleTimeString() : ''}}
          </template>

          <template v-slot:item.rateOpened="{ item }">
            {{item.rateOpened.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}  ) }}%
          </template>

          <template v-slot:item.rateClicked="{ item }">
            {{item.rateClicked.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}  ) }}%
          </template>

        </v-data-table>

      </v-col>
    </v-row>

    <template v-else>

      <div class="pl-3">
<!--        <v-btn @click="onReturnToListClicked()">{{'BACK' | i18n}}</v-btn>-->

        <v-btn color="grey" dark @click="onReturnToListClicked">
          <v-icon>mdi-chevron-left</v-icon>
          {{'BACK' | i18n }}
        </v-btn>


      </div>

      <v-row class="pa-3">
        <v-col>
          <v-card>
            <div class="backDiv">
              <div class="campaignNameDiv">{{campaignName}}</div>
              <v-btn @click="onInformationClicked" class="mr-4">{{ 'MAIL_CAMPAIGN_INFO' | i18n }}</v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <MailCampaignDetails :campaign-id-param="getSelectedCampaignId" :show-info-dialog="showInfoDialog"/>
        </v-col>
      </v-row>
    </template>


  </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import MailsService from "@/services/MailsService";
import MailCampaignDetails from "@/views/mailcampaigns/MailCampaignDetails";

export default {
  name: 'MailCampaignsListComponent',

  components: {
    MailCampaignDetails
  },

  props: {
    componentData: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      campaignId: null,

      showInfoDialog: false,

      search: '',
      headers: [
        { text: this.$t('NAME'), align: 'left', value: 'title' },
        { text: this.$t('STATE_FILTER'), align: 'left', value: 'state' },
        { text: this.$t('CREATED'), align: 'left', value: 'created' },
        { text: this.$t('SCHEDULED'), align: 'left', value: 'scheduled' },

        { text: this.$t('EXPR_RATE_OPENED'), align: 'left', value: 'rateOpened' },
        { text: this.$t('EXPR_RATE_CLICKED'), align: 'left', value: 'rateClicked' },

        { text: this.$t('MAILS_TOTAL'), align: 'right', value: 'nTotal' },
        { text: this.$t('MAIL_TRACKING_SEND_PENDING'), align: 'right', value: 'nPending' },
        { text: this.$t('MAIL_TRACKING_SENT'), align: 'right', value: 'nSent' },
        { text: this.$t('EXPR_COUNT_FAILED'), align: 'right', value: 'nFailed' },
      ],

      loading: false,

      rows: [],

      sortBy: 'title',

      selectedCampaignName: null,

      infoDialog: false
    }
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getMailCampaignIds']),

    campaignName() {
      if(this.getSelectedCampaignId && this.rows && this.rows.length>0) {
        return this.rows.filter(row => row.id===this.getSelectedCampaignId)[0].title

//        return this.rows[0].title
      }

      return ""
    },

    showCampaignDetails() {
      return this.getMailCampaignIds.length>0
    },

    getSelectedCampaignId() {
      const ids = this.getMailCampaignIds
      return (ids.length===1) ? ids[0] : null
    },


  },

  created: function () {
    this.initialFetchData();
  },

  methods: {
    initialFetchData() {
      this.loading = true
      MailsService.fetchMailCampaignsListData(this.getAuthToken, this.activeSurvey.extName,  this.componentData.config.id, this.getFilteredDrillPath).then((response) => {
        this.rows = response.data.rows
        this.loading = false
      })
    },

    onRowClicked(row) {
      this.$store.commit('setFilter', {filterName: 'mcids', filterValue: [row.id]})
    },

    onReturnToListClicked() {
      this.$store.commit('setFilter', {filterName: 'mcids', filterValue: []})
    },

    onInformationClicked() {
      this.showInfoDialog = !this.showInfoDialog
    },


  },


}
</script>

<style scoped>

.backDiv {
  display: flex;
  align-items: center;
  padding: 1em;
}

div.campaignNameDiv {
  font-size: 140%;
  font-weight: bold;
  margin-right: 1em;
}

</style>

