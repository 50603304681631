<template>
<div>
  <template v-if="dataLoaded">
    <highcharts :options="chartOptions"></highcharts>
  </template>
  <template v-else>
    <v-skeleton-loader type="image"></v-skeleton-loader>
  </template>
</div>
</template>

<script>
  import {mapGetters} from "vuex";
  import {Chart} from 'highcharts-vue'
  import ResultsService from "../../services/ResultsService";
  import ColorManager from "../../colormanager";
  import ChartUtil from "../../chartutil";
  // import Highcharts from "highcharts"

  export default {
    name: 'EffectChartComponent',

    components: {
        highcharts: Chart
    },

  data () {
      return {
          dataLoaded: false,
          chartOptions: {
            lang: ChartUtil.getLangTranslations(this),
            exporting: ChartUtil.getExportingSettings(),

              chart: {
                  type: 'bar'
              },
              title: null,

              xAxis: {
                  categories: ['2019']
              },
              yAxis: {
                  min: 0,
                  title: {
                      text: this.$t('EFFECT')
                  }
              },

              tooltip: {
                  enabled: false
              },

              legend: {
                  reversed: true
              },

              plotOptions: {
                  series: {
                      stacking: 'percent',
                      dataLabels: {
                          formatter: function () {
                              return Math.round(100 * this.y / this.total) + '%';
                          },
                          enabled: true
                      }

                  },
              },

              series: []
          }

    }
  },

  props: {
    componentData: Object
  },

    watch: {
        $route: {
            handler() {
                this.loadChartData()
          },
            immediate: true
        }
    },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath'])
  },

  methods: {
      loadChartData() {
        this.dataLoaded = false;
        ResultsService.fetchPriorityChartData(this.getAuthToken, this.getActiveSurvey.extName, this.componentData.id, this.getFilteredDrillPath).then((response) => {
          this.updateChart(response.data)
        })
      },

      updateChart(chartdata) {
          this.dataLoaded = true;
          let colorManager = new ColorManager()
          this.chartOptions.xAxis.categories = [chartdata.effectNode]
          this.chartOptions.series = chartdata.effectSeries;
          for(let s of this.chartOptions.series) {
            s.color = colorManager.getColor(s.name)
          }
      }

  },

}
</script>
<style scoped>
</style>
