<template>
  <v-container fluid>
    <v-row :justify="componentData.justify">
      <v-col :cols="cdata.componentDef.cols" v-for="(cdata, idx) in componentData.components" v-bind:key="idx">
        <editor-component :component-data="cdata" :sample-id="sampleId" :state="state" ref="childComponents"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'GroupComponent',

  components: {
    'editor-component': () => import('./EditorComponent.vue')
  },

  props: {
    componentData: Object,
    sampleId: Number,
    state: Number
  },


  methods: {
    requestSave() {
      for(const cc of this.$refs.childComponents) {
        cc.requestSave()
      }
    },
  }


}
</script>
