<template>
  <v-container fluid>
    <v-row v-if="!importResult || importResult.error">
      <v-col>
        <v-btn @click="onImportClicked">{{'DATA_UPLOADER_IMPORT' | i18n}}</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="importResult && importResult.ok">
      <v-col>
         {{importResult.ok}}
      </v-col>
    </v-row>
    <v-row v-else-if="importResult && importResult.error">
      <v-col>
        {{importResult.error}}
      </v-col>
    </v-row>

    <template v-if="!importResult || importResult.error">
      <v-row  v-if="showStatePicker">
        <v-col cols="1">
          <v-select
              v-model="selectedState"
              :items="mappingStructure.states"
              item-text="name"
              item-value="value"
              :label="$t('STATE_FILTER')"></v-select>
        </v-col>
      </v-row>

      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left" >
              {{ 'DATA_UPLOADER_COLUMN_HEADER' | i18n }}
            </th>
            <th class="text-left">
              {{'DATA_UPLOADER_FIRST_ROW' | i18n }}
            </th>
            <th class="text-left">
              {{'DATA_UPLOADER_FIELD_MAP' | i18n }}
            </th>
          </tr>
          </thead>
          <tbody>
          <ColumnComponent v-for="(ssColumn,idx) in spreadsheetStructure.columns" :spreadsheet-column="ssColumn" :mapping-structure="mappingStructure" :key="idx" @fieldmapped='fieldMapped' />
          </tbody>
        </template>
      </v-simple-table>


    </template>


  </v-container>
</template>

<script>

import {mapGetters} from "vuex";
import ColumnComponent from "@/views/pages/pagecomponents/datauploader/ColumnComponent";
import UploadService from "@/services/UploadService";

export default {
  name: 'DataMapperComponent',
  components: {
    ColumnComponent
  },

  data() {
    return {
      selectedState: null,
      resultingColumnMap: {},
      importResult: null
    }
  },

  props: {
    filename: String,
    configId: String,
    mappingStructure: Object,
    spreadsheetStructure: Object
  },

  mounted() {
    this.selectedState = this.mappingStructure.states[0].value
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'getLocale']),

    showStatePicker() {
      return this.mappingStructure && this.mappingStructure.states.length>1
    }

  },

  methods: {
    fieldMapped(e) {
      this.resultingColumnMap[e.variable] = e.idx
    },

    onImportClicked() {
      UploadService.handleMapping(this.getAuthToken, this.getActiveSurvey.extName, this.configId, this.filename, {
        state: this.selectedState,
        fieldMapping: this.resultingColumnMap
      }).then((response) => {
            this.importResult = response.data

            if(response.data.ok) {
              this.$emit('importedfile')
            }

          }
      )
    }

  }
}
</script>

<style scoped>

</style>
