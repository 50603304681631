<template>
    <QuestionComponent :is-cancelled="false" :can-edit-state="true" :question-data="componentData.questionFieldData"  :validation-message-map="validationMessageMap"></QuestionComponent>
</template>

<script>

// import _ from 'lodash'

import QuestionComponent from "@/views/samples/QuestionComponent";
export default {
  name: 'VariableFieldComponent',

  components: {QuestionComponent},

  data: () => ({
    validationMessageMap: {}
  }),

  props: {
    componentData: Object,
    // validationMessageMap: Object,
    canEditState: Boolean,
    isCancelled: Boolean
  },

  computed: {
  },

  methods: {
    requestSave() {}
  },

}
</script>
<style scoped>
</style>