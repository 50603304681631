<template>
    <v-btn @click="onEditClicked()" :block="componentData.block" :color="componentData.color">{{componentData.title}}</v-btn>
</template>

<script>

import {mapGetters, mapState} from "vuex";

export default {
  name: 'EditButtonComponent',

  components: {},

  data: () => ({}),

  props: {
    componentData: Object,
    sampleId: Number
  },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'rankingList', 'searchTerm', 'filterTerm']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getActiveSurvey', 'getFilterTerm']),
  },

  methods: {
    requestSave() {},

    onEditClicked() {
      this.$root.$emit('editsample')
    }
  }

}
</script>
<style scoped>
</style>