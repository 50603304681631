import backend from '@/services/backend'

class UploadFilesService {
    upload(authToken, surveyName, configId, file/*, onUploadProgress*/) {
        let formData = new FormData();
        formData.append("files", file);

        return backend(
            { method: 'post',
              url: '/vuejs/datauploader/uploadfile/' + surveyName + '/' + configId,
              data: formData,
              headers: { Authorization: authToken, "Content-Type": "multipart/form-data" }
            })
    }

    handleMapping(authToken, surveyName, configId, filename, mappingData) {
        return backend(
            { method: 'post',
                url: '/vuejs/datauploader/handlemapping/' + surveyName + '/' + configId + '/' + filename,
                data: mappingData,
                headers: { Authorization: authToken }
            })

    }

    getFiles() {
        return backend.get("/files");
    }

    fetchImportSets(authToken, surveyName, configId) {
        return backend.get('/vuejs/datauploader/fetchimportsets/' + surveyName + '/' + configId, { headers: { Authorization: authToken } })
    }

    deleteImportSet(authToken, surveyName, importSetId) {
        return backend.delete('/vuejs/datauploader/deleteimportset/' + surveyName + '/' + importSetId, { headers: { Authorization: authToken } })
    }
}
export default new UploadFilesService();
