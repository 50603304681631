<template>
  <div>
    <img v-show="square && !hideSquare" width="48" height="48" style="margin-right: 20px" :src="logoFilename"  alt="logo" @load="hideSquare=false" @error="hideSquare=true"/>
    <img v-if="!square" width="128" height="22" :src="logoFilename"  alt="logo"/>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'LogoComponent',

  props: {
    square: Boolean
  },

  data () {
    return {
      hideSquare: true
    }
  },

  computed: {
    ...mapState(['userIdentity']),

    logoFilename() {
      let url = "https://dashboard.aganalytics.dk/files/dashboard_logos/"

      if(this.square) {
        url += ((this.userIdentity && this.userIdentity.universe) ? this.userIdentity.universe.toLowerCase() + "_square.png" : "ag_logo_square.png")
      } else {
        url += ((this.userIdentity && this.userIdentity.universe) ? this.userIdentity.universe.toLowerCase() + ".png" : "ag_logo.png")
      }

      return url;
    }

  },

  methods: {
    onImgLoad () {
      this.hideSquare = false
    }
  }

}
</script>