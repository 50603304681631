<template>
  <v-card v-if="editorData">

    <HeaderPanelComponent :component-data="headerComponentsData" :sample-id="sampleId"/>

    <v-form ref="form" v-if="editorData">
      <v-container fluid>
        <EditorComponent v-for="(cdata, idx) in regularComponentsData" v-bind:key="idx" :component-data="cdata" :sample-id="sampleId" :state="editorData.state" ref="childComponents"/>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import SamplesService from "@/services/SamplesService";
import {mapGetters, mapState} from "vuex";
import EditorComponent from "@/components/sampleditor/EditorComponent";
import HeaderPanelComponent from "@/components/sampleditor/HeaderPanel";
import Pagenavigator from "@/pagenavigator";

export default {
  name: "SampleEditor",
  components: {
    HeaderPanelComponent,
    EditorComponent
  },

  props: {
    configId: String,
    sampleId: Number,
  },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'rankingList', 'searchTerm', 'filterTerm']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getActiveSurvey', 'getFilterTerm']),

    regularComponentsData() {
      return this.editorData.componentsData.filter(cdata => cdata.componentDef.type!=='HeaderPanelComponent')
    },

    headerComponentsData() {
      return this.editorData.componentsData.filter(cdata => cdata.componentDef.type==='HeaderPanelComponent')[0]
    }

  },

  created: function () {
    SamplesService.fetchSampleEditorData(this.getAuthToken, this.getActiveSurvey.extName, this.configId, this.sampleId).then((response) => {
      this.editorData = response.data
    })
  },

  mounted() {
    this.addEventListener()
  },

  beforeDestroy: function () {
    this.removeEventListener()
  },

  data() {
    return {
      editorData: null,
      validationMessageMap: {}
    }
  },

  methods: {

    requestSave() {
      for(const cc of this.$refs.childComponents) {
        cc.requestSave()
      }
    },

    onUpdateClicked(closeOnUpdate) {
      this.requestSave()
      const parent = this

      SamplesService.updateSampleEditorData(this.getAuthToken, this.getActiveSurvey.extName, this.configId, this.sampleId, this.editorData, false).then((/*response*/) => {
        if(closeOnUpdate) {
          parent.$emit('update')
        }
      })

    },

    onChangeState(componentData) {
      if(this.editorData.state===componentData.fromState) {
        this.editorData.state = componentData.toState

        this.requestSave();
        const parent = this

        SamplesService.updateSampleEditorData(this.getAuthToken, this.getActiveSurvey.extName, this.configId, this.sampleId, this.editorData, true).then((/*response*/) => {
          parent.$emit('update')
        })
      }
    },


    onCancel() {
      this.$emit('cancel')
    },

    onEdit() {
      const pageId = this.$route.params.pageId

      SamplesService.updateSampleEditorData(this.getAuthToken, this.getActiveSurvey.extName, this.configId, this.sampleId, this.editorData, false).then((/*response*/) => {
        Pagenavigator.navigateToSamplePage(this, this.getActiveSurvey.extName, pageId, this.sampleId)
      })

    },

    onResend(componentData) {
      SamplesService.resendInvitation(this.getAuthToken, this.getActiveSurvey.extName, this.sampleId, componentData.email).then((/*response*/) => {
        componentData.disabled = true
      })
    },

    onSendMail(componentData) {
      SamplesService.handleMailActionButton(this.getAuthToken, this.getActiveSurvey.extName, this.sampleId, componentData.template, componentData.mailVariable).then((/*response*/) => {
        this.$store.commit('showSnackbar', this.$t("SAMPLE_EDITOR_MAIL_ACTION_SNACK"))
      })
    },


    addEventListener: function () {
      this.$root.$on('updatesample', (closeOnUpdate) => { this.onUpdateClicked(closeOnUpdate) })
      this.$root.$on('cancel', () => { this.onCancel() })
      this.$root.$on('editsample', () => { this.onEdit() })
      this.$root.$on('resend', (componentData) => { this.onResend(componentData) })
      this.$root.$on('changestate', (componentData) => { this.onChangeState(componentData) })
      this.$root.$on('sendmail', (componentData) => { this.onSendMail(componentData) })
    },

    removeEventListener: function () {
      this.$root.$off('updatesample')
      this.$root.$off('cancel')
      this.$root.$off('editsample')
      this.$root.$off('resend')
      this.$root.$off('changestate')
      this.$root.$off('sendmail')
    }

  },
};
</script>
