<template>
    <v-container fluid fill-height>
        <v-row align-center justify-center>
          <v-col offset=4 cols="4" class="logocol">
            <img width="220px" src="../assets/login_logo.png">
          </v-col>

          <v-col offset=4 cols="4">
            <v-card class="elevation-12 pa-10">
              <v-card-title class="mainTitle">Set password</v-card-title>
              <v-card-subtitle class="subTitle">Assign a new password to your user</v-card-subtitle>

              <v-card-text>
                <v-form @keyup.native.enter="onLogin" v-model="valid">

                    <v-text-field  v-model.lazy.trim="password" id="password" name="password"
                                  label="new password" type="password" required/>
                    <v-text-field :rules="passwordRules" v-model.lazy.trim="password2" id="password2" name="password2"
                                  label="repeat" type="password" required/>
                </v-form>

                <div class="loginButtonPanel">
                  <v-btn :disabled="!valid" type="submit" color="primary" @click="onSetPassword">Set password</v-btn>
                </div>

              </v-card-text>

            </v-card>
          </v-col>
        </v-row>

        <v-row no-gutters class="footerRow">
          ag analytics &middot; +45 7020 1075 &middot; <a class="frontLink" href="mailto://support@aganalytics.dk">support@aganalytics.dk</a>
        </v-row>

    </v-container>
</template>

<script>
  import {mapGetters, mapState} from 'vuex'
  import PageNavigator from "../pagenavigator"
  import AuthService from "../services/AuthService";

export default {
  name: 'forgot',

  data: function () {
    return {
      valid: false,
      password: '',
      password2: ''
    }
  },

  computed: {
    ...mapState(['userIdentity', 'activeSurvey', 'activeDrillPath']),
    ...mapGetters(['getFilteredDrillPath']),

    passwordRules() {
      return  [
        (v) => ( this.password===null  ||  (v === this.password) ) || 'Passwords must match'
      ]},
  },


  methods: {
      onSetPassword: function () {
          if( this.valid ) {

              AuthService.resetPassword(this.$route.params.resetToken, this.password).then(() => {
                  this.$store.commit('setSnackText', "Password has been set")
                  PageNavigator.navigateToLoginPage(this)
              })
          }
    },

  },

}
</script>

<style scoped>
  div.loginButtonPanel {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1em;
  }

  div.logocol {
    display: flex;
    justify-content: center;
  }

  div.mainTitle {
    justify-content: center;
    /*font-size: 30px;*/
    font-size: calc((30/16)*1rem);
  }

  div.subTitle {
    text-align: center;
    /*font-size: 18px;*/
    font-size: calc((18/16)*1rem);
  }

  div.footerRow {
    align-self: flex-end;
    justify-content: center;
    color: #888888
  }

  a.frontLink {
    margin-left: 6px;
    color: #888888;
    text-decoration: none;
  }

</style>

