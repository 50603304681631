<template>
  <div>
    <v-card outlined>
      <v-form ref="form" v-model="formValidity">

        <v-container class="pa-2" fluid>
          <v-row align="center" justify="space-between">
            <v-col>
              <div class="headline">{{value.state}}</div>
            </v-col>
            <v-col>
              <div class="actionButtonContainer">
                <v-btn v-if="showResendEmailButton" @click="onResendEmailClicked" :disabled="resendButtonsDisabled"  class="mr-4" color="primary" px1 dark>{{'RESEND_MAIL_INVITATION' | i18n}}</v-btn>
                <v-btn v-if="showResendSMSButton" @click="onResendSMSClicked"     :disabled="resendButtonsDisabled"  class="mr-4" color="primary" px1 dark>{{'RESEND_SMS_INVITATION' | i18n}}</v-btn>

                <template v-if="showApproveActionButton">
                  <CommentDialog v-if="hasApprovedCommentQuestionField"
                                 v-model="approvedCommentQuestionField.value"
                                 color="primary"
                                 headline="APPROVED_COMMENT_PLACEHOLDER"
                                 title="ACTION_TO_APPROVE"/>
                  <v-btn v-else @click="onApproveActionClicked" class="mr-4" color="primary" px1 dark>{{'ACTION_TO_APPROVE' | i18n}}</v-btn>
                </template>

                <template v-if="showCancelActionButton">
                  <CommentDialog v-if="hasCancelledCommentQuestionField"
                                 v-model="cancelledCommentQuestionField.value"
                                 color="error" headline="CANCELLED_COMMENT_PLACEHOLDER"
                                 title="ACTION_TO_CANCEL"
                                 @do-action="onCancelAction"/>
                  <v-btn v-else  @click="onCancelAction" class="mr-4" color="warning" dark>{{'ACTION_TO_CANCEL' | i18n}}</v-btn>
                </template>

                <template v-if="showUnapproveActionButton">
                  <EvaluationPeriodDialog v-model="value.evalPeriodQuestion"  color="error" headline="EVALUATION_PERIOD" title="ACTION_TO_UNAPPROVE" @do-action="onUnapproveAction"/>
                </template>

                <v-btn v-if="showDeleteActionButton" @click="onDeleteClicked" :disabled="disableDelete"  class="mr-4" color="#BF0000" px1 dark>{{'DELETE' | i18n}}!</v-btn>
                <v-btn @click="onUpdateClicked" :disabled="!canEditState"  class="mr-4" color="#BFBFBF" px1 dark>{{'UPDATE' | i18n}}</v-btn>

              </div>
            </v-col>
          </v-row>

          <v-divider/>

          <v-row class="pl-2 pr-2">
            <v-col cols="4">
              <QuestionComponent :question-data="value.stateQuestion" :can-edit-state="canEditState" :validation-message-map="validationMessageMap"/>
            </v-col>
            <v-col cols="4">
              <QuestionComponent :question-data="value.evalPeriodQuestion" :can-edit-state="canEditState" :validation-message-map="validationMessageMap"/>
            </v-col>
            <v-col cols="4">
              <QuestionComponent v-if="value.subStateQuestion" :can-edit-state="canEditState" :question-data="value.subStateQuestion" :validation-message-map="validationMessageMap"/>
            </v-col>
          </v-row>

          <v-divider/>

          <v-row v-for="(field, idx) in value.groups" v-bind:key="idx">
            <v-col cols="12">
              <QuestionGroupComponent :can-edit-state="canEditState" :question-group-data="field" :validation-message-map="validationMessageMap" :is-cancelled="isStateCancelled"  />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
            </v-col>
          </v-row>
        </v-container>

      </v-form>

    </v-card>


  </div>

</template>

<script>

import {mapGetters, mapState} from "vuex";
import QuestionGroupComponent from "./QuestionGroupComponent";
import SamplesService from "../../services/SamplesService";
import QuestionComponent from "./QuestionComponent";
import CommentDialog from "./CommentDialog";
import EvaluationPeriodDialog from "./EvaluationPeriodDialog";
import _ from 'lodash'
import PageNavigator from "@/pagenavigator"

export default {
  name: 'SampleComponent',

  components: {
    CommentDialog,
    EvaluationPeriodDialog,
    QuestionComponent,
    QuestionGroupComponent
  },

  props: {
    newState: String,
    value: Object
  },

  data: () => ({
    formValidity: true,
    validationMessageMap: {},
    disableDelete: false
  }),

  computed: {
    ...mapState(['interviewData', 'activeSurvey']),
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath', 'isUserRoot']),

    canCancel() {
      return this.value.canCancelSamples
    },

    canPostpone() {
      return this.value.canPostpone
    },

    canEditState() {
      return this.value.canEditState
    },

    hasSampleExtendedState() {
      return this.value.hasSampleExtendedState
    },

    showApproveActionButton() {
      return this.canEditState && (this.isStateNew || (this.isStateApproved && this.value.showApproveButtonInState2)) || (this.isStateUnapproved && this.value.showApproveButtonInState3)
    },

    showDeleteActionButton() {
      return this.isUserRoot;
    },

    showResendEmailButton() {
      return this.value.canResendEmail
    },

    showResendSMSButton() {
      return this.value.canResendSMS
    },

    resendButtonsDisabled() {
      return this.value.isResendDisabled
    },

    showCancelActionButton() {
      return this.canEditState && (!this.isStateCancelled && this.canCancel);
    },

    showUnapproveActionButton() {
      return this.canEditState && (!this.isStateUnapproved && this.canPostpone && this.hasSampleExtendedState)
    },

    hasCancelledCommentQuestionField() {
      return this.cancelledCommentQuestionField !== null
    },

    hasApprovedCommentQuestionField() {
      return this.approvedCommentQuestionField !== null
    },

    hasUnapprovedCommentQuestionField() {
      return this.unapprovedCommentQuestionField !== null
    },


    cancelledCommentQuestionField() {
      for(const qgroup of this.value.groups) {
        for(const qfield of qgroup.fields) {
          if(qfield.cancelledComment) {
            return qfield
          }
        }
      }

      return null;
    },

    approvedCommentQuestionField() {
      for(const qgroup of this.value.groups) {
        for(const qfield of qgroup.fields) {
          if(qfield.approvedComment) {
            return qfield
          }
        }
      }

      return null;
    },



// public static final int STATE_COMPLETED = 0;
// public static final int STATE_SAMPLE_NEW = 1;
// public static final int STATE_SAMPLE_APPROVED = 2;
// public static final int STATE_SAMPLE_UNAPPROVED = 3;   // not ready yet
// public static final int STATE_SAMPLE_CANCELLED = 4;    // not approved  REFACTOR

    isStateCompleted() {
      return this.value.stateQuestion.value === "0";
    },

    isStateNew() {
      return this.value.stateQuestion.value === "1";
    },

    isStateApproved() {
      return this.value.stateQuestion.value === "2";
    },

    isStateUnapproved() {
      return this.value.stateQuestion.value === "3";
    },

    isStateCancelled() {
      return this.value && this.value.stateQuestion.value === "4";
    },

  },



  methods: {
    onResendEmailClicked() {
//                this.updateAndReturnToList( this.$t('MESSAGE_RESEND'), true, false);
      this.validateAndResend(this.$t('MESSAGE_RESEND'), true, false);
    },

    onResendSMSClicked() {
//                this.updateAndReturnToList(this.$t('MESSAGE_RESEND'), false, true);
      this.validateAndResend(this.$t('MESSAGE_RESEND'), false, true);
    },


    validateAndResend(snackText, resendEmail, resendSMS) {

      if(this.$refs.form.validate()) {
        const sampleId = this.$route.params.sampleId

        SamplesService.validateSampleServerside(this.getAuthToken, this.getActiveSurvey.extName, sampleId, this.value.stateQuestion.value, this.value).then((response) => {
          this.validationMessageMap = response.data.questionIdToMessages

          if(_.isEmpty(this.validationMessageMap, true)) {
            this.updateAndReturnToList(snackText, resendEmail, resendSMS)
          }
        })
      }


    },




    onUpdateClicked() {
      if(this.hasCancelledCommentQuestionField) {
        this.cancelledCommentQuestionField.value = this.updatedCancelComment
      }

      this.updateAndReturnToList(this.$t('SAMPLE_UPDATED'), false, false)
    },

    onValidateClicked() {
      this.$refs.form.validate()
    },

    onCancelAction(dto) {
      if(dto) {
        if(this.hasCancelledCommentQuestionField) {
          this.cancelledCommentQuestionField.value = dto.comment
        }
      }

      // this.validateForStateChangeAndUpdate(4, this.$t('SAMPLE_CANCELLED'))

      this.value.stateQuestion.value = 4;
      this.updateAndReturnToList(this.$t('SAMPLE_CANCELLED'), false, false)
    },

    onApproveActionClicked(dto) {
      if(dto && this.hasApprovedCommentQuestionField) {
        this.approvedCommentQuestionField.value = dto.comment
      }

      this.validateForStateChangeAndUpdate(2, this.$t('SAMPLE_APPROVED'))

      // if(this.validateForStateChange(2)) {
      //     this.value.stateQuestion.value = "2";
      //     this.updateAndReturnToList(this.$t('SAMPLE_APPROVED'), false, false)
      // }

    },

    onUnapproveAction(dto) {
      this.value.evalPeriodQuestion.value = dto.evalPeriod
      this.validateForStateChangeAndUpdate(3, this.$t('SAMPLE_UNAPPROVED'))
    },

    updateAndReturnToList(snackText, resendEmail, resendSMS) {
      const sampleId = this.$route.params.sampleId

      SamplesService.updateSampleData(this.getAuthToken, this.getActiveSurvey.extName, sampleId, resendEmail, resendSMS, this.value).then((/*response*/) => {
        this.$store.commit('setSnackText', snackText)

        const fromPage = this.$route.params.fromPage

        if(fromPage==='samples') {
          PageNavigator.navigateToSamplesPage(this, this.activeSurvey.extName, this.getFilteredDrillPath)
        } else {
          PageNavigator.navigateToPage(this, this.$route.params.fromPage, this.activeSurvey.extName, this.getFilteredDrillPath)
        }

//        this.$router.push({ name: 'samples', params: { surveyExtName: this.activeSurvey.extName, drillPath: this.getFilteredDrillPath } })
      })

    },


    validateForStateChangeAndUpdate(newState, snackText) {

      if(newState === 2) {
        const valResult = this.$refs.form.validate()
        if(!valResult) {
          return;
        }
      }

      if(this.$refs.form.validate()) {
        const sampleId = this.$route.params.sampleId

        SamplesService.validateSampleServerside(this.getAuthToken, this.getActiveSurvey.extName, sampleId, newState, this.value).then((response) => {
          this.validationMessageMap = response.data.questionIdToMessages

          if(_.isEmpty(this.validationMessageMap, true)) {
            this.value.stateQuestion.value = newState;
            this.updateAndReturnToList(snackText, false, false)
          }
        })
      }

    },

    onDeleteClicked() {
      const sampleId = this.$route.params.sampleId

      SamplesService.deleteSample(this.getAuthToken, this.getActiveSurvey.extName, sampleId).then((/*response*/) => {
        this.disableDelete = true
      })
    }

  },


}
</script>

<style scoped>

.actionButtonContainer {
  display: flex;
  justify-content: flex-end;
}

</style>