<template>
  <div class="elevation-1 singleActionWrapper">

    <div>
      <div @click.stop="dialog = true" class="actionWrapper">
        <div class="leftPart">
          <div>
            <div class="actiontitle">{{suggestion.variableText}}</div>
            <div  class="actionSubLabel"><v-icon class="actionIcon">mdi-target</v-icon><div class="valueText">{{'GOAL_ICON_TOOLTIP' | i18n}}: {{suggestion.goalText}}</div></div>
            <div  class="actionSubLabel"><v-icon class="actionIcon">mdi-calendar-range</v-icon><div class="valueText">{{'TIME_PERIOD' | i18n}}: {{suggestion.periodText}}</div></div>
            <div  v-show="!isSuggestion" class="actionSubLabel"><v-icon class="actionIcon">mdi-calendar-clock</v-icon><div class="valueText">{{'DEADLINE' | i18n}}: {{suggestion.deadlineText}}</div></div>
          </div>
        </div>

        <div class="rightPart">

          <div class="scoreBar">
            <div class="scorePart" :style="{color: valueColor}">{{suggestion.endValueText}}</div>

            <div class="barPart">
              <ScoreGoalBarComponent :vardto="suggestion.varData" :force-grey="isClosed"/>
            </div>
          </div>

          <div v-if="isClosed" class="iconDiv mt-2">
            <v-icon  :color="iconColor">{{iconName}}</v-icon>
            <span class="completionText">{{completionResult}}</span>
          </div>

          <div class="mt-2" v-else-if="isSuggestion">
            <v-btn text><v-icon class="mr-1">mdi-plus-circle-outline</v-icon>{{'ADD_SUGGESTION' | i18n}}</v-btn>
          </div>


        </div>

      </div>

      <v-dialog v-model="dialog" width="60%">
        <ActionEditForm :dialog="dialog" @close="onClose" :id="suggestion.id"/>
      </v-dialog>

    </div>

  </div>

</template>

<script>
import ActionEditForm from "./ActionEditForm";
import ScoreGoalBarComponent from "./ScoreGoalBarComponent";

export default {
  name: 'SingleActionComponent',

  components: { ActionEditForm, ScoreGoalBarComponent },

  props: {
    suggestion: Object,
    isSuggestion: Boolean
  },

  data () {
    return {
      dialog: false
    }
  },


  computed: {
    iconName() {
      if(this.suggestion.state===1) {
        return "mdi-check-circle-outline"
      } else if(this.suggestion.state===2) {
        return "mdi-close-circle-outline"
      }

      return "mdi-circle-outline"
    },

    iconColor() {
      // if(this.suggestion.state===1) {
      //     return '#00c896'
      // } else if(this.suggestion.state===2) {
      //     return '#b9133e'
      // }

      return "grey"
    },

    endValueLabel() {
      return this.suggestion.state===0 ? "Current value" : "End value";
    },

    completionResult() {
      return this.suggestion.state===1 ? this.$t("ACTION_COMPLETED") : this.$t("ACTION_FAILED")
    },

    isClosed() {
      return this.suggestion.state!==0
    },

    valueColor() {
      const goalBarColor = '#909ba7'

      const  belowColor = this.suggestion.varData.inverse ? '#00c896' : '#b9133e'
      const  aboveColor = this.suggestion.varData.inverse ? '#b9133e' : '#00c896'

      if(this.isClosed) {
        return goalBarColor
      }

      if(this.suggestion.varData.value!==null && this.suggestion.varData.goalValue!==null) {
        return (this.suggestion.varData.value >= this.suggestion.varData.goalValue) ? aboveColor : belowColor;
      }

      return '#909ba7';
    }

  },

  methods: {
    onClose(value) {
      this.dialog = false

      if(value) {
        this.$emit('actionchanged')
      }

    }
  }


}
</script>

<style scoped>

.actionWrapper {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.actiontitle {
  /*font-size: 20px;*/
  font-size: calc((20/16)*1rem);
  color: #000;
  padding-bottom: 20px
}

.subtitle {
  color: black;
}

.actionIcon {
  /*font-size: 16px;*/
  font-size: calc((16/16)*1rem);
  /*padding-bottom: 4px;*/
  padding-right: 8px;
}

.actionSubLabel {
  /*font-size: 14px;*/
  font-size: calc((14/16)*1rem);
  color: rgba(0,0,0,.54);
  display: flex;
}

div.scoreBar {
  display: flex;
  flex-direction: column;
  align-items: center;
}







.leftPart {
  display: flex;
}

.rightPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.scorePart {
  /*font-size: 35px;*/
  font-size: calc((35/16)*1rem);
  font-weight: 500;
  /*margin-bottom: 20px;*/
}

.barPart {
  height: 10px;
  width: 200px
}

.iconDiv {
  display: flex;
  /*margin-right: 8px;*/
  /*align-self: flex-end;*/
}

.datePart {
  display: flex;
  /*align-items: center;*/
}


.completionText {
  margin-left: 4px;
  /*font-size: 14px;*/
  font-size: calc((14/16)*1rem);
  letter-spacing: 1.25px;
  font-weight: 500;
  text-transform: uppercase;
  color: grey;
}

div.valueText {
  display: flex;
  align-items: center;
}

</style>
