<template>
  <v-card>
    <div v-if="renderedMail" v-html="renderedMail"> </div>
    <div v-else class="waitingDiv">
      <div>
        Loading mail
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
    </div>

  </v-card>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import MailsService from "@/services/MailsService";

export default {
  name: 'RenderedMail',

  components: {},

  props: {
    campaignId: Number,
    template: String,
    trackingId: Number
  },

  data: () => ({
    loading: false,
    renderedMail: null
  }),

  created: function () {
    MailsService.fetchRenderedMailData(this.getAuthToken, this.activeSurvey.extName, this.campaignId, this.trackingId, this.template).then((response) => {
      this.renderedMail = response.data
    })
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),
  },

}
</script>

<style scoped>

.waitingDiv {
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

</style>
